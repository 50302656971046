function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { Price } from 'swag-client-common/utils/price';
import { getIsDynamicBundle } from 'swag-client-common/utils/reseller/get-is-dynamic-bundle.util';
import { STATE_LOADING } from '../redux.constants';
import { sizeDependentPriceSelector, sizeSelector, sizeIndexSelector as productBuilderSizeIndexSelector, selectedVariantsImagesSelector, variantIndexSelector, selectedProductionTimeSelector, quantitySelector as productBuilderQuantitySelector, isFullDesignProductSelector, productSelector, productCategoriesIdSelector, isCustomizedBlankSelector, isLimitedEditionSelector, currentProductionTimeListSelector } from '../product/product.selectors';
import { categoriesMapSelector } from '../product-listing/product-listing.selectors';
import { logosSelector, layersSelector, textsSelector } from '../logo/logo.selectors';
const defaultLogos = {
  front: {
    colors: {
      colorsToPrint: ['Black']
    }
  },
  back: {
    colors: {
      colorsToPrint: ['Black']
    }
  },
  left: {
    colors: {
      colorsToPrint: ['Black']
    }
  },
  right: {
    colors: {
      colorsToPrint: ['Black']
    }
  }
};
export const productNameSelector = state => {
  const {
    product: {
      product: {
        name
      }
    }
  } = state;
  return name;
};
export const colorsNumbersSelector = state => {
  const {
    instantQuote: {
      colorsNumbers,
      locations
    }
  } = state;
  const calculatedColorsNumbers = [];
  const defaultMinColors = isCustomizedBlankSelector(state) ? 0 : 1;
  for (let i = 0; i < locations; i++) {
    const colorsNumber = colorsNumbers[i] === null || colorsNumbers[i] === undefined ? defaultMinColors : colorsNumbers[i];
    calculatedColorsNumbers.push(colorsNumber);
  }
  return calculatedColorsNumbers;
};
export const colorsNumbersErrorsSelector = state => state.instantQuote.colorsNumbersErrors;
export const sizeIndexSelector = state => state.instantQuote.sizeIndex;
export const locationsSelector = state => state.instantQuote.locations;
export const locationsErrorSelector = state => state.instantQuote.locationsError;
export const quantitySelector = state => state.instantQuote.quantity;
export const resultQuantitySelector = state => state.instantQuote.result.quantity;
export const pricePerItemSelector = state => state.instantQuote.result.pricePerItem;
export const minQuantSelector = state => state.instantQuote.minQuant;
export const quantityErrorSelector = state => state.instantQuote.quantityError;
export const isPriceShownSelector = state => state.instantQuote.isPriceShown;
export const zipSelector = state => state.instantQuote.zip;
export const productionPriceSelector = state => state.instantQuote.result.totalPrice;
export const shippingPriceSelector = state => state.instantQuote.shippingPrice;
export const showLoadingOverlaySelector = state => state.instantQuote.status === STATE_LOADING;
export const shippingErrorSelector = state => state.instantQuote.shippingError;
export const shippingCountrySelector = state => state.instantQuote.country;
export const isEmailRequestToHubspotFormSubmitted = state => state.instantQuote.isEmailRequestToHubspotFormSubmitted;
export const isNotApparelSelector = state => {
  const categoriesId = productCategoriesIdSelector(state);
  const categories = categoriesMapSelector(state);
  const list = [];
  categoriesId.forEach(({
    categoryId
  }) => {
    const category = Object.values(categories).filter(c => c._id === categoryId)[0];
    if (category) {
      list.push(category);
    }
  });
  const isApparel = list.every(category => category.parent_id === categories.apparel._id);
  return !isApparel;
};
export const shipmentCitySelector = state => state.instantQuote.shipmentCity;
export const isTaxableSelector = createSelector(isNotApparelSelector, shipmentCitySelector, (isNotApparel, shipmentCity) => isNotApparel && shipmentCity === 'New York');
export const taxesPriceSelector = state => state.instantQuote.result.taxesPrice;
export const setupFeeSelector = state => state.instantQuote.result.setupFee;
export const setupFeeByColorsSelector = createSelector(productSelector, colorsNumbersSelector, selectedProductionTimeSelector, currentProductionTimeListSelector, locationsSelector, (product, colorsNumbers, productionTime, productionTimeList, locations) => {
  let colorsAmount = colorsNumbers.reduce((colorsSum, number) => {
    let amount = number;
    if (product.addOneColor) {
      amount += 1;
    }
    return amount + colorsSum;
  }, 0);
  if (product.colorIndependentPrice || product.colorIndependentScreenPrice) {
    colorsAmount = 1 * locations;
  }
  const setupFee = product.screenPrice * colorsAmount;
  const timeCost = setupFee * Price.getTimeCoefficient(productionTime, productionTimeList);
  return setupFee + timeCost;
});
export const orderItemSelector = createSelector(quantitySelector, colorsNumbersSelector, locationsSelector, sizeIndexSelector, variantIndexSelector, selectedProductionTimeSelector, isCustomizedBlankSelector, (quantity, colorsNumbers, locations, sizeIndex, variantIndex, selectedProductionTime, isCustomizedBlank) => {
  const logos = Object.keys(defaultLogos).slice(0, locations).reduce((acc, logoLocation, locationIndex) => _objectSpread({}, acc, {
    [logoLocation]: [{
      colors: {
        colorsToPrint: new Array(colorsNumbers[locationIndex] || 1).fill('Color').map((color, index) => color + index)
      },
      original: 'someUrl'
    }]
  }), {});
  const colors = getIsDynamicBundle() ? colorsNumbers[0] : colorsNumbers.reduce((sum, v) => sum + v, 0);
  return {
    quantity,
    colors,
    logos: isCustomizedBlank ? [] : logos,
    variantInd: variantIndex,
    sizeInd: sizeIndex,
    prodTime: selectedProductionTime,
    isCustomizedBlank
  };
});
export const totalPriceSelector = createSelector(isPriceShownSelector, productionPriceSelector, shippingPriceSelector, taxesPriceSelector, (isPriceShown, productionPrice, shippingPrice, taxesPrice) => {
  if (!isPriceShown) {
    return null;
  }
  const totalPrice = productionPrice + taxesPrice;
  return Number.isFinite(+shippingPrice) ? totalPrice + shippingPrice : totalPrice;
});
export const maxLocationsSelector = createSelector(selectedVariantsImagesSelector, currentVariantImages => Object.keys(currentVariantImages).length);
export const submitDisabledSelector = createSelector(locationsErrorSelector, colorsNumbersErrorsSelector, quantityErrorSelector, locationsSelector, (locationError, colorsErrors, quantityError, locationsNumber) => {
  const noLocations = !locationsNumber;
  return locationError || colorsErrors.slice(0, locationsNumber).some(error => error) ||
  // cutting of errors for non-active locations
  quantityError || noLocations;
});
export const instantQuoteSelector = createSelector([sizeDependentPriceSelector, sizeIndexSelector, sizeSelector, quantitySelector, resultQuantitySelector, quantityErrorSelector, minQuantSelector, locationsSelector, locationsErrorSelector, maxLocationsSelector, colorsNumbersSelector, colorsNumbersErrorsSelector, submitDisabledSelector, totalPriceSelector, pricePerItemSelector, shippingPriceSelector, showLoadingOverlaySelector, isFullDesignProductSelector, productNameSelector, taxesPriceSelector, setupFeeSelector, shippingErrorSelector, shippingCountrySelector, isCustomizedBlankSelector, isLimitedEditionSelector], (sizeDependentPrice, sizeIndex, sizes, quantity, resultQuantity, quantityError, minQuant, locations, locationsError, maxLocations, colorsNumbers, colorsNumbersErrors, isSubmitDisabled, totalPrice, pricePerItem, shippingPrice, showLoadingOverlay, isFullDesignProduct, productName, taxesPrice, setupFee, shippingError, shippingCountry, isCustomizedBlank, isLimitedEdition) => ({
  isSizeDependentPrice: sizeDependentPrice && sizes,
  sizeIndex,
  sizes,
  quantity,
  resultQuantity,
  quantityError,
  minQuant,
  locations,
  locationsError,
  maxLocations,
  colorsNumbers,
  colorsNumbersErrors,
  isSubmitDisabled,
  totalPrice,
  pricePerItem,
  shippingPrice,
  showLoadingOverlay,
  isFullDesignProduct,
  productName,
  taxesPrice,
  setupFee,
  shippingError,
  shippingCountry,
  isCustomizedBlank,
  isLimitedEdition
}));
export const prefilledDataSelector = state => {
  const quantity = productBuilderQuantitySelector(state);
  const sizeIndex = productBuilderSizeIndexSelector(state);
  const media = layersSelector(state);
  const logosData = logosSelector(state);
  const textsData = textsSelector(state);
  const locations = Object.keys(media || {}).reduce((locationsNumber, locationName) => media[locationName].length > 0 ? locationsNumber + 1 : locationsNumber, 0);
  const colorsNumbers = Object.keys(media || {}).reduce((acc, locationName) => {
    const colorsNumber = media[locationName].reduce((colorsPerSide, mediaId) => !textsData[mediaId] && logosData[mediaId] && logosData[mediaId].colors.colorsToPrint.length + colorsPerSide, 0) || null;
    return [...acc, colorsNumber];
  }, []);
  return {
    locations: Math.max(locations, 1),
    sizeIndex,
    quantity,
    colorsNumbers
  };
};