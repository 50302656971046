import _omit from "lodash/omit";
import { DesignUploadMode } from 'swag-common/interfaces';
import { DEFAULT_EMBELLISHMENT_NAME, FULL_DESIGN_EMBELLISHMENT_NAME } from 'swag-common/constants/product.constants';
export const getItemSelectedProductSettings = (product, item) => {
  var _product$productSetti, _product$productSetti2, _product$productSetti3;
  const selectedProductSettings = (_product$productSetti = (_product$productSetti2 = product.productSettings) === null || _product$productSetti2 === void 0 ? void 0 : _product$productSetti2.find(({
    embellishmentMethodId,
    embellishmentMethod
  }) => {
    var _item$embellishmentMe;
    if (item.isCustomizedBlank && !embellishmentMethodId) {
      return true;
    }
    const itemEmbellishmentId = (_item$embellishmentMe = item.embellishmentMethod) === null || _item$embellishmentMe === void 0 ? void 0 : _item$embellishmentMe._id;
    const isSameEmbellishmentById = itemEmbellishmentId && embellishmentMethodId && itemEmbellishmentId === embellishmentMethodId;
    const isSameEmbellishmentByName = (embellishmentMethod === null || embellishmentMethod === void 0 ? void 0 : embellishmentMethod.name) && item.embellishmentMethod && item.embellishmentMethod === (embellishmentMethod === null || embellishmentMethod === void 0 ? void 0 : embellishmentMethod.name);
    if (!item.isCustomizedBlank && (isSameEmbellishmentById || isSameEmbellishmentByName)) {
      return true;
    }
    if (!item.embellishmentMethod && item.logos && Object.keys(item.logos).length > 0 && ((embellishmentMethod === null || embellishmentMethod === void 0 ? void 0 : embellishmentMethod.name) === DEFAULT_EMBELLISHMENT_NAME || product.designUploadMode === DesignUploadMode.fullDesign && (embellishmentMethod === null || embellishmentMethod === void 0 ? void 0 : embellishmentMethod.name) === FULL_DESIGN_EMBELLISHMENT_NAME)) {
      return true;
    }
    if (!item.embellishmentMethod && (!item.logos || Object.keys(item.logos).length === 0) && embellishmentMethodId === null) {
      return true;
    }
    return false;
  })) !== null && _product$productSetti !== void 0 ? _product$productSetti : (_product$productSetti3 = product.productSettings) === null || _product$productSetti3 === void 0 ? void 0 : _product$productSetti3[0];
  return selectedProductSettings ? [_omit(selectedProductSettings, 'embellishmentMethod')] : [];
};