import _isEqual from "lodash/isEqual";
import _isBoolean from "lodash/isBoolean";
import _isEmpty from "lodash/isEmpty";
import _sortBy from "lodash/sortBy";
import _sumBy from "lodash/sumBy";
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { addQuantities, subtractQuantities } from 'swag-client-common/utils/order-item.utils';
import { Price } from 'swag-client-common/utils/price';
import { checkIsApparel, getBoxProductDataForBoxBuilderPopup, getTotalCount } from 'swag-client-common/utils/product.utils';
import { getValidationMessages } from 'swag-client-common/utils/validations';
import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { DesignUploadMode } from 'swag-common/interfaces';
import { parseCmyk } from 'swag-common/utils/cmyk.util';
import isFullDesign from 'swag-common/utils/custom-store-product/product-is-full-design.util';
import { isItemBlankSampleOrPrintedSample } from 'swag-common/utils/items/is-item-sample.service';
import { isSuperSpeedItem } from 'swag-common/utils/items/is-item-super-speed.util';
import { getTotalQuantityForSharedItems, isBox, isBoxSuperSpeedType, isItemBoxAddon, isItemBoxSpecialCrinklePaper, isItemBoxSpecialNotecard, isItemBoxSpecialProduct, isItemBoxSpecialTypeOrAddon } from 'swag-common/utils/order/item-is-box.util';
import { isInBox } from 'swag-common/utils/order/item-is-in-box.util';
import { isSuperSpeedProductionOption } from 'swag-common/utils/product/product.utils';
import { getProductKey } from '../../../custom-store-admin/redux/order-more-box/order-more-box.utils';
import { addOnProductsForBoxSelector, allItemsInCartAndDraftSelector, alreadyHaveBoxInCartOrSaveForLaterSelector, cartItemsSelector, setupFeeSelector, specialBoxProductsSelector, stockLevelsByProductIdAndVariantSkuSelector, supplierInventoryLevelsCartAndDraftSelector } from '../cart/cart.selectors';
import { getImageOfItem } from '../cart/cart.utils';
import { getItemPriceFromProduct } from '../product/product-other.utils';
import { getProductVariantsToChoose, isProductOutOfStock } from '../product/product.selectors';
import { BOX_TYPE_TO_STEP, initialState, STEPS } from './box-builder.constants';
import { defineAsSplittedItem, getAmountOfSelectedProducts, populateCardItemWithValidation } from './box-builder.utils';
import { editBoxItems } from './edit-box-items';
import { getNonBoxReminderInCart } from './remove-box-items';
const rootSelector = state => state.boxBuilder;
export const editBoxBuilderItemsIdSelector = createSelector(rootSelector, state => state.editBoxBuilderItemsId);
export const editingBoxTypeBoxIdSelector = createSelector(rootSelector, state => state.editingBoxTypeBoxId);
export const editingBoxSelector = createSelector(cartItemsSelector, editingBoxTypeBoxIdSelector, editBoxBuilderItemsIdSelector, (cartItems, editingBoxTypeBoxId, editBoxBuilderItemsId) => {
  const id = editingBoxTypeBoxId || editBoxBuilderItemsId;
  const box = cartItems.find(item => item._id === id);
  if (!box) {
    return null;
  }
  return box;
});
export const originalEditingBoxTypeSelector = createSelector(editingBoxSelector, box => box === null || box === void 0 ? void 0 : box.originalBoxType);
export const boxNameSelector = createSelector(rootSelector, state => state.boxName);
export const selectedBackButtonOnStepSelector = createSelector(rootSelector, state => state.selectedBackButtonOnStep);
export const selectedForwardButtonOnStepSelector = createSelector(rootSelector, state => state.selectedForwardButtonOnStep);
export const boxInfoStepCompletedSelector = createSelector(rootSelector, state => state.boxInfoStepCompleted);
export const selectedBoxTypeSelector = createSelector(rootSelector, state => state.selectedBoxType);
export const customBoxInfoSelector = createSelector(rootSelector, state => state.customBoxInfo);
export const brownBoxInfoSelector = createSelector(rootSelector, state => state.brownBoxInfo);
export const customBoxLogoLoadingSelector = createSelector(rootSelector, state => state.customBoxLogoLoading);
export const brownBoxLogoLoadingSelector = createSelector(rootSelector, state => state.brownBoxLogoLoading);
export const fullyCustomBoxInfoSelector = createSelector(rootSelector, state => state.fullyCustomBoxInfo);
export const coloredBoxInfoSelector = createSelector(rootSelector, state => state.coloredBoxInfo);
export const productCardSelectionsSelector = createSelector(rootSelector, state => state.productCardSelections);
export const addonCardSelectionsSelector = createSelector(rootSelector, state => state.addonCardSelections);
export const isEditingBoxTypeBoxIdSelector = createSelector(editingBoxTypeBoxIdSelector, Boolean);
export const editingBoxNameBoxIdSelector = createSelector(rootSelector, state => state.editingBoxNameBoxId);
export const isEditingBoxNameBoxIdSelector = createSelector(editingBoxNameBoxIdSelector, Boolean);
export const editQuantityInCartItemDataSelector = createSelector(rootSelector, state => state.editQuantityInCartItemData);
export const editingQuantityInCartSelector = createSelector(rootSelector, state => state.editingQuantityInCart);
export const editedQuantityInCartDataSelector = createSelector(rootSelector, state => state.editedQuantityInCartData);
export const isEditBoxBuilderItemsIdSelector = createSelector(editBoxBuilderItemsIdSelector, Boolean);
export const isEditModeSelector = createSelector(isEditBoxBuilderItemsIdSelector, isEditingBoxNameBoxIdSelector, isEditingBoxTypeBoxIdSelector, (isEditBoxBuilder, isEditingBoxName, isEditingBoxType) => {
  return isEditBoxBuilder || isEditingBoxName || isEditingBoxType;
});
function isForBox(item, editBoxBuilderItemsId, boxItemsId) {
  var _item$prod;
  if (item.boxItemsId && item.boxItemsId !== boxItemsId) {
    return false;
  }
  if (isItemBoxSpecialTypeOrAddon(item)) {
    return false;
  }
  if (isItemBlankSampleOrPrintedSample(item)) {
    return false;
  }
  if (item.boxItemsId && !editBoxBuilderItemsId || isBox(item)) {
    return false;
  }
  return !((_item$prod = item.prod) !== null && _item$prod !== void 0 && _item$prod.forbiddenForBox);
}
export const showSuperSpeedConfPopupSelector = createSelector(editingBoxSelector, cartItemsSelector, (editingBox, cartItems) => {
  var _editingBox$prod;
  return (editingBox === null || editingBox === void 0 ? void 0 : (_editingBox$prod = editingBox.prod) === null || _editingBox$prod === void 0 ? void 0 : _editingBox$prod.isBoxForSuperSpeed) && cartItems.some(item => isSuperSpeedItem(item) && item.boxItemsId === editingBox.boxItemsId);
});
export const selectedOrExistedBoxTypeSelector = createSelector(editingBoxSelector, selectedBoxTypeSelector, cartItemsSelector, (box, selectedBoxType) => {
  var _box$prod;
  if (selectedBoxType) {
    return selectedBoxType;
  }
  const valueFromCart = box === null || box === void 0 ? void 0 : (_box$prod = box.prod) === null || _box$prod === void 0 ? void 0 : _box$prod.specialProductType;
  return box ? valueFromCart : selectedBoxType;
});
export const currentSpecialBoxProductSelector = createSelector(selectedOrExistedBoxTypeSelector, specialBoxProductsSelector, (selectedBoxType, specialBoxProducts) => specialBoxProducts.find(product => product.specialProductType === selectedBoxType));
export const minQuantitySelector = createSelector(currentSpecialBoxProductSelector, box => {
  var _box$minQuant;
  return (_box$minQuant = box === null || box === void 0 ? void 0 : box.minQuant) !== null && _box$minQuant !== void 0 ? _box$minQuant : 0;
});
const lowerProductQtyInCartSelector = createSelector(cartItemsSelector, minQuantitySelector, (cartItems, boxMinQty) => {
  var _cartItems$filter;
  const filteredItems = (_cartItems$filter = cartItems === null || cartItems === void 0 ? void 0 : cartItems.filter(item => {
    var _item$prod2, _item$prod3;
    return !item.boxItemsId && !((_item$prod2 = item.prod) !== null && _item$prod2 !== void 0 && _item$prod2.specialProductType) && !((_item$prod3 = item.prod) !== null && _item$prod3 !== void 0 && _item$prod3.forbiddenForBox);
  })) !== null && _cartItems$filter !== void 0 ? _cartItems$filter : [];
  const variantsQty = filteredItems === null || filteredItems === void 0 ? void 0 : filteredItems.reduce((acc, {
    variants
  }) => {
    variants.forEach(({
      quantities
    }) => {
      const variantQty = quantities.reduce((acc, num) => acc + num, 0);
      acc.push(variantQty);
    });
    return acc;
  }, []);
  if (variantsQty.length) {
    const productQtyLowerThanBoxMinQty = variantsQty.some(qty => qty <= boxMinQty);
    if (productQtyLowerThanBoxMinQty) {
      return boxMinQty;
    }
    return Math.min(...variantsQty);
  }
  return boxMinQty;
});
export const currentBoxesQuantitySelector = createSelector(rootSelector, editingBoxSelector, lowerProductQtyInCartSelector, (state, box, lowerProductQty) => {
  if (Number.isFinite(state.currentBoxesQuantity)) {
    return state.currentBoxesQuantity || 0;
  }
  if (box) {
    return box.quantity || 0;
  }
  return lowerProductQty;
});
export const selectedOrExistedBoxNameSelector = createSelector(editingBoxNameBoxIdSelector, boxNameSelector, allItemsInCartAndDraftSelector, (editingBoxId, name, items) => {
  if (editingBoxId && !name) {
    const box = items.find(item => String(item._id) === editingBoxId);
    return (box === null || box === void 0 ? void 0 : box.name) || '';
  } else {
    return name;
  }
});
export const selectedOrExistedFullyCustomBoxInfoSelector = createSelector(editingBoxSelector, fullyCustomBoxInfoSelector, (editingBox, customBoxInfo) => {
  if (editingBox) {
    if (customBoxInfo === null) {
      return {
        note: (editingBox === null || editingBox === void 0 ? void 0 : editingBox.designNotes) || ''
      };
    } else {
      return customBoxInfo;
    }
  } else {
    return customBoxInfo;
  }
});
export const selectedOrExistedColoredBoxInfoSelector = createSelector(editingBoxSelector, coloredBoxInfoSelector, (editingBox, coloredBoxInfo) => {
  if (editingBox) {
    if (coloredBoxInfo === null) {
      var _editingBox$prod2, _editingBox$prod2$var, _editingBox$prod2$var2, _editingBox$variants$;
      return {
        color: (editingBox === null || editingBox === void 0 ? void 0 : (_editingBox$prod2 = editingBox.prod) === null || _editingBox$prod2 === void 0 ? void 0 : (_editingBox$prod2$var = _editingBox$prod2.variants) === null || _editingBox$prod2$var === void 0 ? void 0 : (_editingBox$prod2$var2 = _editingBox$prod2$var[(_editingBox$variants$ = editingBox.variants[0]) === null || _editingBox$variants$ === void 0 ? void 0 : _editingBox$variants$.variantIndex]) === null || _editingBox$prod2$var2 === void 0 ? void 0 : _editingBox$prod2$var2.color) || null
      };
    } else {
      return coloredBoxInfo;
    }
  } else {
    return coloredBoxInfo;
  }
});
const getParsedCmykColor = hexColor => {
  return hexColor ? parseCmyk(hexColor) : null;
};
const getBoxLogo = ({
  updatedLogo,
  oldLogo,
  isOldLogoAvailable
}) => {
  return updatedLogo || (isOldLogoAvailable ? oldLogo : null);
};
export const customBoxInfoValuesSelector = createSelector(editingBoxSelector, customBoxInfoSelector, (editingBox, customBoxInfo) => {
  var _editingBox$prod3, _editingBox$logos, _editingBox$logos$fro;
  if (!editingBox) {
    return customBoxInfo;
  }
  const isOldLogoAvailable = (editingBox === null || editingBox === void 0 ? void 0 : (_editingBox$prod3 = editingBox.prod) === null || _editingBox$prod3 === void 0 ? void 0 : _editingBox$prod3.specialProductType) === SPECIAL_PRODUCT_TYPES.CUSTOM_BOX;
  const oldLogo = editingBox === null || editingBox === void 0 ? void 0 : (_editingBox$logos = editingBox.logos) === null || _editingBox$logos === void 0 ? void 0 : (_editingBox$logos$fro = _editingBox$logos.front) === null || _editingBox$logos$fro === void 0 ? void 0 : _editingBox$logos$fro[0];
  const logoFromCustomBox = customBoxInfo === null || customBoxInfo === void 0 ? void 0 : customBoxInfo.logo;
  const logo = getBoxLogo({
    updatedLogo: logoFromCustomBox,
    oldLogo,
    isOldLogoAvailable
  });
  const color = (customBoxInfo === null || customBoxInfo === void 0 ? void 0 : customBoxInfo.color) || getParsedCmykColor(editingBox === null || editingBox === void 0 ? void 0 : editingBox.boxColor);
  return {
    logo,
    color
  };
});
export const brownBoxInfoValuesSelector = createSelector(editingBoxSelector, brownBoxInfoSelector, cartItemsSelector, (editingBox, brownBoxInfo, cartItems) => {
  var _editingBox$prod4, _cartItems$find, _cartItems$find$logos, _cartItems$find$logos2;
  if (!editingBox) {
    return brownBoxInfo;
  }
  const isOldLogoAvailable = (editingBox === null || editingBox === void 0 ? void 0 : (_editingBox$prod4 = editingBox.prod) === null || _editingBox$prod4 === void 0 ? void 0 : _editingBox$prod4.specialProductType) === SPECIAL_PRODUCT_TYPES.BROWN_BOX;
  const oldLogo = cartItems === null || cartItems === void 0 ? void 0 : (_cartItems$find = cartItems.find(item => {
    var _item$prod4;
    return (item === null || item === void 0 ? void 0 : (_item$prod4 = item.prod) === null || _item$prod4 === void 0 ? void 0 : _item$prod4.specialProductType) === SPECIAL_PRODUCT_TYPES.BOX_TAPE && item.boxItemsId === editingBox.boxItemsId;
  })) === null || _cartItems$find === void 0 ? void 0 : (_cartItems$find$logos = _cartItems$find.logos) === null || _cartItems$find$logos === void 0 ? void 0 : (_cartItems$find$logos2 = _cartItems$find$logos.front) === null || _cartItems$find$logos2 === void 0 ? void 0 : _cartItems$find$logos2[0];
  const logoFromBrownBox = brownBoxInfo === null || brownBoxInfo === void 0 ? void 0 : brownBoxInfo.tapeLogo;
  const tapeLogo = getBoxLogo({
    updatedLogo: logoFromBrownBox,
    oldLogo,
    isOldLogoAvailable
  });
  return {
    tapeLogo
  };
});
export const selectedOrExistedCustomBoxInfoSelector = createSelector(editingBoxSelector, customBoxInfoSelector, (editingBox, customBoxInfo) => {
  if (editingBox) {
    if (customBoxInfo === null) {
      var _editingBox$logos2, _editingBox$logos2$fr, _editingBox$logos2$fr2, _editingBox$logos3, _editingBox$logos3$fr;
      return editingBox !== null && editingBox !== void 0 && editingBox.boxColor && editingBox !== null && editingBox !== void 0 && (_editingBox$logos2 = editingBox.logos) !== null && _editingBox$logos2 !== void 0 && (_editingBox$logos2$fr = _editingBox$logos2.front) !== null && _editingBox$logos2$fr !== void 0 && (_editingBox$logos2$fr2 = _editingBox$logos2$fr[0]) !== null && _editingBox$logos2$fr2 !== void 0 && _editingBox$logos2$fr2.original ? {
        color: parseCmyk(editingBox === null || editingBox === void 0 ? void 0 : editingBox.boxColor),
        logo: editingBox === null || editingBox === void 0 ? void 0 : (_editingBox$logos3 = editingBox.logos) === null || _editingBox$logos3 === void 0 ? void 0 : (_editingBox$logos3$fr = _editingBox$logos3.front) === null || _editingBox$logos3$fr === void 0 ? void 0 : _editingBox$logos3$fr[0]
      } : null;
    } else {
      var _editingBox$logos4, _editingBox$logos4$fr;
      const parsedCmyk = editingBox !== null && editingBox !== void 0 && editingBox.boxColor ? parseCmyk(editingBox === null || editingBox === void 0 ? void 0 : editingBox.boxColor) : null;
      return _objectSpread({}, customBoxInfo, customBoxInfo.logo ? customBoxInfo.logo : editingBox === null || editingBox === void 0 ? void 0 : (_editingBox$logos4 = editingBox.logos) === null || _editingBox$logos4 === void 0 ? void 0 : (_editingBox$logos4$fr = _editingBox$logos4.front) === null || _editingBox$logos4$fr === void 0 ? void 0 : _editingBox$logos4$fr[0], customBoxInfo.color ? customBoxInfo.color : parsedCmyk);
    }
  } else {
    return customBoxInfo;
  }
});
function getSizesOfCardItem({
  prod,
  prodSizeOptions
}) {
  if (!prod) {
    return [];
  }
  const {
    sizeDependentPrice,
    hasSize
  } = prod;
  if (sizeDependentPrice || !hasSize) {
    return [];
  }
  return prodSizeOptions;
}
export function getCartItemWithEditedQuantities(currentItem, cartItems, editedQuantityInCartData, currentBoxesQuantity, boxItemsId) {
  const cartItemsWithEditedQuantity = getModifiedCartItemsWithNewQuantities({
    cartItems,
    currentItem,
    currentBoxesQuantity,
    editedQuantityInCartData,
    boxItemsId
  });
  const blendedQuantity = getBlendedQuantity(currentItem, cartItemsWithEditedQuantity);
  const currentCartItem = cartItemsWithEditedQuantity.find(item => item._id === currentItem._id);
  return _objectSpread({}, currentCartItem, {
    blendedQuantity
  });
}
function getModifiedCartItemsWithNewQuantities({
  cartItems,
  currentItem,
  currentBoxesQuantity,
  editedQuantityInCartData,
  boxItemsId
}) {
  return cartItems.reduce((result, cartItem) => {
    if (isBox(currentItem) && currentItem.boxItemsId === boxItemsId && currentBoxesQuantity) {
      var _currentItem$variants, _currentItem$variants2, _currentItem$variants3;
      const variantIndex = (_currentItem$variants = currentItem === null || currentItem === void 0 ? void 0 : (_currentItem$variants2 = currentItem.variants) === null || _currentItem$variants2 === void 0 ? void 0 : (_currentItem$variants3 = _currentItem$variants2.at(0)) === null || _currentItem$variants3 === void 0 ? void 0 : _currentItem$variants3.variantIndex) !== null && _currentItem$variants !== void 0 ? _currentItem$variants : 0;
      return [...result, _objectSpread({}, cartItem, {
        quantity: currentBoxesQuantity,
        variants: [{
          variantIndex,
          quantities: [currentBoxesQuantity]
        }]
      })];
    }
    if (currentItem.asRelatedItemId && cartItem.asRelatedItemId !== currentItem.asRelatedItemId) {
      return result;
    }
    const newVariants = getNewVariantsForModifiedCartItem(cartItem, editedQuantityInCartData, cartItems, boxItemsId || currentItem.boxItemsId);
    const quantity = isItemBoxAddon(currentItem) ? cartItem.quantity : _sumBy(newVariants, variant => getTotalCount(variant.quantities));
    result.push(_objectSpread({}, cartItem, {
      quantity,
      variants: newVariants
    }));
    return result;
  }, []);
}
function getNewVariantsForModifiedCartItem(cartItem, editedQuantityInCart, cartItems, boxItemsId) {
  const {
    variants,
    _id
  } = cartItem;
  return variants.map(_ref => {
    var _editedQuantityInCart;
    let {
        variantIndex,
        quantities
      } = _ref,
      rest = _objectWithoutProperties(_ref, ["variantIndex", "quantities"]);
    let editedQuantities = editedQuantityInCart === null || editedQuantityInCart === void 0 ? void 0 : (_editedQuantityInCart = editedQuantityInCart[_id]) === null || _editedQuantityInCart === void 0 ? void 0 : _editedQuantityInCart[variantIndex];
    const reminder = getNonBoxReminderInCart({
      alreadyHandledItems: [],
      cartItems,
      cartItem
    });
    if (reminder) {
      const reminderVariant = reminder.variants.find(v => v.variantIndex === variantIndex);
      if (reminderVariant) {
        var _editedQuantities;
        editedQuantities = (_editedQuantities = editedQuantities) === null || _editedQuantities === void 0 ? void 0 : _editedQuantities.map((q, i) => q - reminderVariant.quantities[i]);
      }
    }
    return _objectSpread({}, rest, {
      variantIndex,
      quantities: getEditedQuantitiesForItems({
        editedQuantities,
        quantities,
        boxItemsId
      })
    });
  });
}
function getEditedQuantitiesForItems({
  editedQuantities,
  quantities,
  boxItemsId
}) {
  if (!editedQuantities) {
    return quantities;
  }
  if (boxItemsId) {
    return editedQuantities.map((q, index) => q + quantities[index]);
  }
  return editedQuantities;
}
export function getBlendedQuantity(item, modifiedItems) {
  if (isBox(item)) {
    return null;
  }
  if (isItemBoxAddon(item)) {
    return item.blendedQuantity;
  }
  return _sumBy(modifiedItems, item => item.quantity);
}
function getItemPriceValue(currentItem, cartItems, editedQuantityInCartData, setupFee) {
  const currentCartItem = getCartItemWithEditedQuantities(currentItem, cartItems, editedQuantityInCartData);
  if (!currentCartItem) {
    return 0;
  }
  const totalQuantityForSharedItems = getTotalQuantityForSharedItems(currentCartItem, cartItems.map(item => item._id === currentCartItem._id ? currentCartItem : item));
  const quantityToUse = currentCartItem.quantity || currentItem.quantity;
  const price = getItemPriceFromProduct({
    orderItem: _objectSpread({}, currentCartItem, {
      blendedQuantity: currentCartItem.blendedQuantity,
      quantity: quantityToUse
    }),
    product: currentCartItem.prod,
    blendedQuantity: currentCartItem.blendedQuantity,
    totalQuantityForSharedItems,
    discardMultiplier: false
  });
  const screenPrice = Price.getScreenPriceForRelatedProduct(setupFee, currentCartItem.blendedQuantity || quantityToUse);
  return Price.getPricePerItem({
    assemblyFee: 0,
    quantity: quantityToUse,
    screenPrice,
    totalPriceForItems: price
  });
}
function getCorrectVariantQuantities({
  isApparel,
  quantitiesInThisBox,
  quantitiesInCart,
  quantityPerBox,
  currentBoxesQuantity,
  sizeInd,
  sizes,
  sizeDependentPrice,
  hasSize
}) {
  if (isApparel) {
    return quantitiesInThisBox || quantitiesInCart;
  }
  if (sizeDependentPrice && hasSize) {
    const quantities = new Array(sizes.length).fill(0);
    quantities[sizeInd] = quantityPerBox * currentBoxesQuantity;
    return quantities;
  }
  return [quantityPerBox * currentBoxesQuantity];
}
function getProductCardItemData(item, params) {
  var _item$prod5, _item$prod5$size, _item$prod6;
  const {
    currentBoxesQuantity,
    isAddonFlow,
    addonCardSelections,
    setupFee,
    productCardSelections,
    editedQuantityInCartData,
    editBoxBuilderItemsId,
    cartItems,
    editedBoxItemsId
  } = params;
  const prodSizeOptions = (_item$prod5 = item.prod) === null || _item$prod5 === void 0 ? void 0 : (_item$prod5$size = _item$prod5.size) === null || _item$prod5$size === void 0 ? void 0 : _item$prod5$size.options;
  const productName = ((_item$prod6 = item.prod) === null || _item$prod6 === void 0 ? void 0 : _item$prod6.name) || '';
  const itemSelections = isAddonFlow ? addonCardSelections[item._id] : productCardSelections[item._id];
  const itemPriceValue = isAddonFlow ? Price.getItemPriceWithoutSetUpFee(_objectSpread({}, item, {
    setupFee
  })) : getItemPriceValue(item, cartItems, editedQuantityInCartData, setupFee);
  const cartItem = cartItems.find(i => i._id === item._id || i.prodId === item._id) || {};
  return item.variants.reduce((acc, variant) => {
    var _item$prod7, _item$prod8, _item$prod9, _item$prod10, _editedQuantityInCart2, _item$prod11, _item$prod12, _item$prod13, _item$prod13$limitedE, _item$prod14, _item$prod14$productS, _item$prod14$productS2, _item$prod15, _item$prod16;
    const defaultVariant = {
      color: '',
      colorFamily: '',
      images: {},
      isHidden: false,
      isAlwaysInStockVariant: false,
      name: '',
      variantId: ''
    };
    const prodVariant = ((_item$prod7 = item.prod) === null || _item$prod7 === void 0 ? void 0 : _item$prod7.variants.find((prodVariant, index) => index === variant.variantIndex)) || defaultVariant;
    const itemVariantSelections = isAddonFlow ? itemSelections : itemSelections === null || itemSelections === void 0 ? void 0 : itemSelections[variant.variantIndex];
    const sizes = getSizesOfCardItem({
      prod: item.prod,
      prodSizeOptions: prodSizeOptions || []
    });
    const quantityPerBox = getItemQuantityPerBox({
      isEditMode: !!editBoxBuilderItemsId,
      cartItem,
      reduxValue: itemVariantSelections === null || itemVariantSelections === void 0 ? void 0 : itemVariantSelections.quantityPerBox
    });
    const isSelected = getIsSelectedCardValue({
      editBoxBuilderItemsId,
      cartItems,
      cartItem,
      reduxValue: itemVariantSelections === null || itemVariantSelections === void 0 ? void 0 : itemVariantSelections.isSelected
    });
    const isApparel = checkIsApparel({
      hasSize: ((_item$prod8 = item.prod) === null || _item$prod8 === void 0 ? void 0 : _item$prod8.hasSize) || false,
      designUploadMode: ((_item$prod9 = item.prod) === null || _item$prod9 === void 0 ? void 0 : _item$prod9.designUploadMode) || DesignUploadMode.logo,
      sizeDependentPrice: ((_item$prod10 = item.prod) === null || _item$prod10 === void 0 ? void 0 : _item$prod10.sizeDependentPrice) || false
    });
    const {
      quantitiesInThisBox,
      quantitiesInCart,
      isCurrentVariantOfItemIsSharedInAnotherBox
    } = getCurrentCartQuantitiesAndQuantitiesInThisBox({
      currentItemCartQuantities: variant.quantities,
      currentItem: item,
      variantIndex: variant.variantIndex,
      cartItems,
      editedBoxItemsId,
      editedQuantitiesInCart: editedQuantityInCartData === null || editedQuantityInCartData === void 0 ? void 0 : (_editedQuantityInCart2 = editedQuantityInCartData[item._id]) === null || _editedQuantityInCart2 === void 0 ? void 0 : _editedQuantityInCart2[variant.variantIndex]
    });
    const quantitiesItem = getQuantitiesOfCardItem({
      isEditMode: !!editBoxBuilderItemsId,
      cartItem,
      variantIndex: variant.variantIndex,
      isAddonFlow,
      itemVariantSelections,
      variantQuantities: getCorrectVariantQuantities({
        isApparel,
        quantitiesInThisBox,
        quantitiesInCart,
        quantityPerBox,
        currentBoxesQuantity,
        sizeInd: item.sizeInd,
        hasSize: !!((_item$prod11 = item.prod) !== null && _item$prod11 !== void 0 && _item$prod11.hasSize),
        sizes: prodSizeOptions !== null && prodSizeOptions !== void 0 ? prodSizeOptions : [],
        sizeDependentPrice: !!((_item$prod12 = item.prod) !== null && _item$prod12 !== void 0 && _item$prod12.sizeDependentPrice)
      })
    });
    const variantForCard = _objectSpread({}, prodVariant, {
      name: (prodVariant === null || prodVariant === void 0 ? void 0 : prodVariant.name) || '',
      images: (prodVariant === null || prodVariant === void 0 ? void 0 : prodVariant.images) || '',
      isHidden: (prodVariant === null || prodVariant === void 0 ? void 0 : prodVariant.isHidden) || false,
      quantities: quantitiesItem,
      variantIndex: variant.variantIndex
    });
    const {
      remainingQuantityInCart,
      totalQuantityInCart
    } = getRemainingQuantity({
      quantityPerBox,
      currentBoxesQuantity,
      quantitiesInCart,
      quantitiesInThisBox,
      isSelected,
      isCurrentVariantOfItemIsSharedInAnotherBox,
      isApparel,
      quantitiesItem
    });
    const isLE = !!((_item$prod13 = item.prod) !== null && _item$prod13 !== void 0 && (_item$prod13$limitedE = _item$prod13.limitedEdition) !== null && _item$prod13$limitedE !== void 0 && _item$prod13$limitedE.isLimitedEdition);
    const isSuperSpeed = isSuperSpeedProductionOption(item === null || item === void 0 ? void 0 : (_item$prod14 = item.prod) === null || _item$prod14 === void 0 ? void 0 : (_item$prod14$productS = _item$prod14.productSettings) === null || _item$prod14$productS === void 0 ? void 0 : (_item$prod14$productS2 = _item$prod14$productS[0]) === null || _item$prod14$productS2 === void 0 ? void 0 : _item$prod14$productS2.productionTimeList[item === null || item === void 0 ? void 0 : item.prodTime]);
    acc.push({
      sizes,
      variant: variantForCard,
      itemId: item._id,
      productName,
      remainingQuantityInCart,
      totalQuantityInCart,
      quantityPerBox,
      itemPriceValue,
      setupFee,
      errorText: '',
      isLE,
      isSuperSpeed,
      logo: getImageOfItem(_objectSpread({}, item, {
        variants: [variantForCard]
      })),
      productIsAlwaysInStock: !!((_item$prod15 = item.prod) !== null && _item$prod15 !== void 0 && _item$prod15.isAlwaysInStock),
      isSelected,
      isLimitedEdition: (_item$prod16 = item.prod) === null || _item$prod16 === void 0 ? void 0 : _item$prod16.limitedEdition.isLimitedEdition,
      quantitiesItem,
      asRelatedItemId: item.asRelatedItemId,
      splitShippedType: item.splitShippedType,
      isQtyError: false,
      cartQuantities: quantitiesInCart,
      quantitiesInThisBox,
      totalSizesQuantity: isApparel ? getTotalCount(quantitiesItem) : null,
      totalCount: currentBoxesQuantity * quantityPerBox
    });
    return acc;
  }, []);
}
function getRemainingQuantity({
  quantityPerBox,
  currentBoxesQuantity,
  quantitiesInCart,
  quantitiesInThisBox,
  isSelected,
  isCurrentVariantOfItemIsSharedInAnotherBox,
  quantitiesItem,
  isApparel
}) {
  const totalQuantityInCart = getTotalCount(quantitiesInCart);
  const totalQuantityInBox = quantitiesInThisBox ? getTotalCount(quantitiesInThisBox) : 0;
  const totalQuantityOfItem = totalQuantityInCart + totalQuantityInBox;
  if (!isSelected && isCurrentVariantOfItemIsSharedInAnotherBox) {
    return {
      totalQuantityInCart: null,
      remainingQuantityInCart: totalQuantityOfItem
    };
  }
  if (!isSelected) {
    return {
      totalQuantityInCart: totalQuantityOfItem,
      remainingQuantityInCart: null
    };
  }
  if (isApparel) {
    const totalAvailableQuantities = addQuantities({
      firstQuantities: quantitiesInCart,
      secondsQuantities: quantitiesInThisBox || []
    });
    return {
      remainingQuantityInCart: getTotalCount(subtractQuantities({
        decreasingQuantity: totalAvailableQuantities || [],
        subtractionQuantity: quantitiesItem || []
      })),
      totalQuantityInCart: null
    };
  }
  const totalForBox = quantityPerBox * currentBoxesQuantity;
  if (totalForBox <= totalQuantityOfItem) {
    return {
      remainingQuantityInCart: totalQuantityOfItem - totalForBox,
      totalQuantityInCart: null
    };
  }
  return {
    remainingQuantityInCart: 0,
    totalQuantityInCart: null
  };
}
const makeFakeItemForAddon = (prod, currentBoxesQuantity, addonCardSelections) => {
  const qtyPerBox = (addonCardSelections === null || addonCardSelections === void 0 ? void 0 : addonCardSelections.quantityPerBox) || 1;
  const quantity = currentBoxesQuantity * qtyPerBox;
  const price = getItemPriceFromProduct({
    orderItem: {
      variantInd: 0,
      sizeInd: 0,
      quantity,
      isCustomizedBlank: true
    },
    product: prod,
    discardMultiplier: false
  });
  return {
    price,
    quantity,
    sizeInd: 0,
    blendedQuantity: quantity,
    _id: prod._id,
    prodId: prod._id,
    prod: prod,
    asRelatedItemId: '',
    variants: [{
      quantities: [quantity],
      variantIndex: 0
    }],
    prodTime: 0,
    splitShippedType: null
  };
};
export const addonCardItemsSelector = createSelector(cartItemsSelector, addOnProductsForBoxSelector, currentBoxesQuantitySelector, addonCardSelectionsSelector, editBoxBuilderItemsIdSelector, editingBoxSelector, (cartItems, addons, currentBoxesQuantity, addonCardSelections, editBoxBuilderItemsId, editingBox) => {
  return addons.reduce((acc, prod) => {
    const item = makeFakeItemForAddon(prod, currentBoxesQuantity, addonCardSelections[prod._id]);
    const variantsToChoose = getProductVariantsToChoose(prod);
    const isOutOfStock = isProductOutOfStock(variantsToChoose, isFullDesign(prod), prod);
    if (isOutOfStock) {
      return acc;
    }
    acc.push(...getProductCardItemData(item, {
      currentBoxesQuantity,
      addonCardSelections,
      editBoxBuilderItemsId,
      isAddonFlow: true,
      setupFee: 0,
      productCardSelections: {},
      editedQuantityInCartData: {},
      cartItems: cartItems,
      editedBoxItemsId: editingBox === null || editingBox === void 0 ? void 0 : editingBox.boxItemsId
    }));
    return acc;
  }, []);
});
const BOX_ID = 'BOX_ID';
function boxToItem(boxProduct, tmpBoxId, currentBoxesQuantity) {
  var _boxProduct$productSe;
  return {
    _id: BOX_ID,
    boxItemsId: tmpBoxId,
    quantity: currentBoxesQuantity,
    itemSku: '',
    prod: {
      minQuant: boxProduct.minQuant,
      _id: boxProduct._id,
      hasSize: boxProduct.hasSize,
      isAlwaysInStock: boxProduct.isAlwaysInStock,
      designUploadMode: boxProduct.designUploadMode,
      productSize: boxProduct.productSize,
      outOfStockThreshold: boxProduct.outOfStockThreshold,
      quantIncrement: boxProduct.quantIncrement,
      sizeDependentPrice: boxProduct.sizeDependentPrice,
      categories: boxProduct.categories,
      size: null,
      num: '',
      variants: [],
      supplierOutOfStockThreshold: null,
      hidden: false,
      limitedEdition: boxProduct.limitedEdition,
      forbiddenForBox: boxProduct.forbiddenForBox,
      productSettings: (_boxProduct$productSe = boxProduct.productSettings) !== null && _boxProduct$productSe !== void 0 && _boxProduct$productSe[0] ? [boxProduct.productSettings[0]] : []
    }
  };
}
function productCardItemToCartItemForValidation(cardItem, itemFromCart, tmpBoxId, currentBoxesQuantity) {
  const prod = _objectSpread({}, (itemFromCart === null || itemFromCart === void 0 ? void 0 : itemFromCart.prod) || {}, {
    supplierOutOfStockThreshold: 0
  });
  const isApparel = checkIsApparel({
    designUploadMode: prod.designUploadMode,
    hasSize: prod.hasSize,
    sizeDependentPrice: prod.sizeDependentPrice
  });
  const quantity = isApparel ? getTotalCount(cardItem.quantitiesItem) : cardItem.quantityPerBox * currentBoxesQuantity;
  return {
    prod,
    quantity,
    _id: cardItem.itemId,
    boxItemsId: tmpBoxId,
    asRelatedItemId: itemFromCart.asRelatedItemId,
    quantityPerBox: cardItem.quantityPerBox,
    isSample: false,
    isPrintedSample: false,
    isOrderMore: itemFromCart === null || itemFromCart === void 0 ? void 0 : itemFromCart.isOrderMore,
    itemSku: itemFromCart === null || itemFromCart === void 0 ? void 0 : itemFromCart.itemSku,
    color: '',
    cartQuantities: cardItem.cartQuantities,
    availableQuantities: getAvailableQuantities({
      quantitiesInCart: cardItem.cartQuantities,
      quantitiesInThisBox: cardItem.quantitiesInThisBox
    }),
    blendedQuantity: itemFromCart.blendedQuantity,
    sizeInd: itemFromCart === null || itemFromCart === void 0 ? void 0 : itemFromCart.sizeInd,
    variants: [{
      quantities: cardItem.quantitiesItem || [],
      variantIndex: cardItem.variant.variantIndex || 0
    }]
  };
}
export function getAvailableQuantities({
  quantitiesInCart,
  quantitiesInThisBox
}) {
  if (quantitiesInThisBox) {
    return quantitiesInCart.map((quantity, index) => {
      return quantity + (quantitiesInThisBox[index] || 0);
    });
  }
  return quantitiesInCart;
}
function getValidations({
  currentSpecialBoxProduct,
  tmpBoxId,
  currentBoxesQuantity,
  cartItems,
  cardItemsWithoutValidation,
  stockLevelsByProductIdAndVariantSku,
  supplierInventoryLevels
}) {
  const items = cardItemsWithoutValidation.reduce((acc, cardItem) => {
    if (!cardItem.isSelected) {
      return acc;
    }
    const itemFromCart = cartItems.find(item => item._id === cardItem.itemId);
    if (itemFromCart) {
      acc.push(productCardItemToCartItemForValidation(cardItem, itemFromCart, tmpBoxId, currentBoxesQuantity));
    }
    return acc;
  }, []);
  return getValidationMessages({
    items,
    boxes: [boxToItem(currentSpecialBoxProduct, tmpBoxId, currentBoxesQuantity)],
    stockLevelsByProductIdAndVariantSku,
    supplierInventoryLevels,
    ignoreThreshold: true,
    customKeyGenerator: item => getProductKey(item._id, item.variants[0].variantIndex)
  });
}
const productCardItemsWithoutValidationsSelector = createSelector(cartItemsSelector, currentBoxesQuantitySelector, productCardSelectionsSelector, editedQuantityInCartDataSelector, editBoxBuilderItemsIdSelector, state => state, (cartItems, currentBoxesQuantity, productCardSelections, editedQuantityInCartData, editBoxBuilderItemsId, state) => {
  var _cartItems$find2;
  const {
    boxItemsId
  } = (_cartItems$find2 = cartItems.find(i => i._id === editBoxBuilderItemsId)) !== null && _cartItems$find2 !== void 0 ? _cartItems$find2 : {};
  return cartItems.reduce((acc, cartItem) => {
    const setupFee = setupFeeSelector(state, {
      item: cartItem
    });
    if (isForBox(cartItem, editBoxBuilderItemsId, boxItemsId)) {
      const editedItem = getModifiedItem(cartItem, cartItems, editBoxBuilderItemsId, boxItemsId);
      const productCardItem = getProductCardItemData(editedItem, {
        setupFee,
        cartItems,
        productCardSelections,
        editBoxBuilderItemsId,
        editedQuantityInCartData,
        isAddonFlow: false,
        addonCardSelections: {},
        editedBoxItemsId: boxItemsId,
        currentBoxesQuantity: currentBoxesQuantity || 0
      });
      acc.push(...productCardItem);
    }
    return acc;
  }, []);
});
export const allValidationsSelector = createSelector(cartItemsSelector, stockLevelsByProductIdAndVariantSkuSelector, currentBoxesQuantitySelector, currentSpecialBoxProductSelector, productCardItemsWithoutValidationsSelector, supplierInventoryLevelsCartAndDraftSelector, (cartItems, stockLevelsByProductIdAndVariantSku, currentBoxesQuantity, currentSpecialBoxProduct, cardItemsWithoutValidation, supplierInventoryLevels) => {
  if (!currentSpecialBoxProduct) {
    return cardItemsWithoutValidation;
  }
  const tmpBoxId = 'tmpBoxId';
  return getValidations({
    tmpBoxId,
    cartItems,
    currentSpecialBoxProduct,
    cardItemsWithoutValidation,
    stockLevelsByProductIdAndVariantSku,
    supplierInventoryLevels,
    currentBoxesQuantity: currentBoxesQuantity || 0
  });
});
export const productCardItemsSelector = createSelector(currentSpecialBoxProductSelector, productCardItemsWithoutValidationsSelector, allValidationsSelector, (currentSpecialBoxProduct, cardItemsWithoutValidation, validations) => {
  if (!currentSpecialBoxProduct) {
    return cardItemsWithoutValidation;
  }
  const cardItemsWithoutValidationAndSplitShipped = cardItemsWithoutValidation.filter(item => !item.splitShippedType);
  return _sortBy(cardItemsWithoutValidationAndSplitShipped.map(populateCardItemWithValidation(validations)), cardItem => cardItem.productName);
});
export const selectedProductsAmountSelector = createSelector(productCardItemsSelector, getAmountOfSelectedProducts);
export const selectedAddonsAmountSelector = createSelector(addonCardItemsSelector, getAmountOfSelectedProducts);
export const currentBoxesQuantityErrorSelector = createSelector(allValidationsSelector, allValidations => {
  const error = allValidations[BOX_ID];
  return error && !error.isSuccessed ? error.message : null;
});
const isBoxTypeSpecificDataCompletedSelector = createSelector(customBoxInfoValuesSelector, selectedOrExistedFullyCustomBoxInfoSelector, selectedOrExistedColoredBoxInfoSelector, brownBoxInfoValuesSelector, (customBoxInfo, fullyCustomBoxInfo, coloredBoxInfo, brownBoxInfo) => {
  return Boolean(customBoxInfo || fullyCustomBoxInfo || coloredBoxInfo || brownBoxInfo);
});
export const isAddonStepRestrictedSelector = createSelector(selectedOrExistedBoxTypeSelector, productCardSelectionsSelector, editBoxBuilderItemsIdSelector, specialBoxProductsSelector, cartItemsSelector, (type, productCardSelections, editBoxBuilderItemsId, specialBoxProducts, cartItems) => {
  const isSuperSpeedType = type && isBoxSuperSpeedType({
    specialBoxProducts,
    type
  });
  if (!isSuperSpeedType) {
    return false;
  }
  const boxItem = cartItems.find(item => item._id === editBoxBuilderItemsId);
  const boxItemsWithoutBox = cartItems.filter(item => item.boxItemsId === (boxItem === null || boxItem === void 0 ? void 0 : boxItem.boxItemsId) && !isItemBoxSpecialProduct(item));
  const nothingToAdd = !_isEmpty(productCardSelections) && Object.values(productCardSelections).every(data => Object.values(data).every(v => !v.isSelected));
  const createModeNothingToAdd = !editBoxBuilderItemsId && (_isEmpty(productCardSelections) || nothingToAdd);
  const editModeNothingToAdd = editBoxBuilderItemsId && !_isEmpty(productCardSelections) && boxItemsWithoutBox.length && boxItemsWithoutBox.every(({
    _id
  }) => _id in productCardSelections && Object.values(productCardSelections[_id]).every(v => !v.isSelected));
  if (createModeNothingToAdd) {
    return false;
  }
  if (editModeNothingToAdd) {
    return false;
  }
  const boxAlreadyHasAddons = cartItems.reduce((res, item) => {
    if (res) {
      return res;
    }
    if (item.boxItemsId === (boxItem === null || boxItem === void 0 ? void 0 : boxItem.boxItemsId)) {
      return isItemBoxAddon(item);
    }
    return res;
  }, false);
  if (boxAlreadyHasAddons) {
    return false;
  }
  const isAllBoxProductsAreSuperSpeedAndNotAddons = cartItems.reduce((acc, item) => {
    if (!acc || isBox(item) || isItemBoxSpecialProduct(item)) {
      return acc;
    }
    const isItemWasInTheBox = !!item.boxItemsId && item.boxItemsId === (boxItem === null || boxItem === void 0 ? void 0 : boxItem.boxItemsId);
    const isItemWasTouched = (item._id in productCardSelections);
    const isItemWasTouchedButStillSelected = isItemWasTouched && Object.values(productCardSelections[item._id]).some(v => v.isSelected);
    const isItemWasNotInTheBox = !item.boxItemsId;
    const isItemCurrentlySelectedForBox = isItemWasInTheBox && (!isItemWasTouched || isItemWasTouchedButStillSelected) || isItemWasNotInTheBox && isItemWasTouchedButStillSelected;
    if (!isItemCurrentlySelectedForBox) {
      return acc;
    }
    return isSuperSpeedItem(item);
  }, true);
  return isSuperSpeedType && isAllBoxProductsAreSuperSpeedAndNotAddons;
});
export const shouldShowSuperSpeedBoxConfirmationSelector = createSelector(selectedOrExistedBoxTypeSelector, productCardSelectionsSelector, editBoxBuilderItemsIdSelector, specialBoxProductsSelector, cartItemsSelector, (type, productCardSelections, editBoxBuilderItemsId, specialBoxProducts, cartItems) => {
  if (_isEmpty(productCardSelections)) {
    return false;
  }
  const hasSuperSpeedInCart = cartItems.some(isSuperSpeedItem);
  if (!hasSuperSpeedInCart) {
    return false;
  }
  const boxItem = cartItems.find(item => item._id === editBoxBuilderItemsId);
  const boxAlreadyHasAddons = cartItems.reduce((res, item) => {
    if (res) {
      return res;
    }
    if (item.boxItemsId === (boxItem === null || boxItem === void 0 ? void 0 : boxItem.boxItemsId)) {
      return isItemBoxAddon(item);
    }
    return res;
  }, false);
  if (boxAlreadyHasAddons) {
    return false;
  }
  const entries = Object.entries(productCardSelections);
  const {
    superSpeedProductsCountInBox,
    selectedEntitiesCount
  } = entries.reduce((res, [id, data]) => {
    if (Object.values(data).every(v => !v.isSelected)) {
      return res;
    }
    const item = cartItems === null || cartItems === void 0 ? void 0 : cartItems.find(({
      _id
    }) => _id === id);
    if (!item) {
      return res;
    }
    return isSuperSpeedItem(item) ? {
      superSpeedProductsCountInBox: res.superSpeedProductsCountInBox + 1,
      selectedEntitiesCount: res.selectedEntitiesCount + 1
    } : {
      superSpeedProductsCountInBox: res.superSpeedProductsCountInBox,
      selectedEntitiesCount: res.selectedEntitiesCount + 1
    };
  }, {
    superSpeedProductsCountInBox: 0,
    selectedEntitiesCount: 0
  });
  return type && isBoxSuperSpeedType({
    specialBoxProducts,
    type
  }) && superSpeedProductsCountInBox < selectedEntitiesCount;
});
const completedStepsSelector = createSelector(boxInfoStepCompletedSelector, selectedOrExistedBoxTypeSelector, boxNameSelector, isBoxTypeSpecificDataCompletedSelector, productCardSelectionsSelector, editingBoxTypeBoxIdSelector, editBoxBuilderItemsIdSelector, (boxInfoStepCompleted, selectedBoxType, boxName, isBoxTypeSpecificDataCompleted, productCardSelections, editingBoxTypeBoxId, editBoxBuilderItemsId) => {
  const COMPLETED_STEPS = [];
  if (editingBoxTypeBoxId && selectedBoxType) {
    COMPLETED_STEPS.push(STEPS.BOX_TYPE_SELECTION);
    COMPLETED_STEPS.push(BOX_TYPE_TO_STEP[selectedBoxType]);
    return COMPLETED_STEPS;
  }
  if (editBoxBuilderItemsId) {
    COMPLETED_STEPS.push(STEPS.SELECT_PRODUCTS_FOR_BOX);
    COMPLETED_STEPS.push(STEPS.SELECT_ADDONS_FOR_BOX);
    return COMPLETED_STEPS;
  }
  if (boxInfoStepCompleted) {
    COMPLETED_STEPS.push(STEPS.BOX_INTRODUCTION_INFO);
  }
  if (boxName !== null) {
    COMPLETED_STEPS.push(STEPS.BOX_NAMING);
  }
  if (selectedBoxType !== null) {
    COMPLETED_STEPS.push(STEPS.BOX_TYPE_SELECTION);
  }
  if (isBoxTypeSpecificDataCompleted && selectedBoxType) {
    COMPLETED_STEPS.push(BOX_TYPE_TO_STEP[selectedBoxType]);
  }
  if (!_isEmpty(productCardSelections) && isBoxTypeSpecificDataCompleted) {
    COMPLETED_STEPS.push(STEPS.SELECT_PRODUCTS_FOR_BOX);
  }
  return COMPLETED_STEPS;
});
export const currentStepSelector = createSelector(completedStepsSelector, selectedBackButtonOnStepSelector, selectedForwardButtonOnStepSelector, alreadyHaveBoxInCartOrSaveForLaterSelector, boxInfoStepCompletedSelector, selectedOrExistedBoxTypeSelector, isBoxTypeSpecificDataCompletedSelector, productCardSelectionsSelector, editingBoxTypeBoxIdSelector, editingBoxNameBoxIdSelector, editBoxBuilderItemsIdSelector, isAddonStepRestrictedSelector, (completedSteps, selectedBackButtonOnStep, selectedForwardButtonOnStep, alreadyHaveBoxInCartOrSaveForLater, boxInfoStepCompleted, selectedBoxType, isBoxTypeSpecificDataCompleted, productCardSelections, editingBoxTypeBoxId, editingBoxNameBoxId, editBoxBuilderItemsId, isAddonStepRestricted) => {
  if (editingBoxNameBoxId) {
    return STEPS.BOX_NAMING;
  }
  if (editBoxBuilderItemsId) {
    if (!selectedForwardButtonOnStep && !selectedBackButtonOnStep) {
      return STEPS.SELECT_PRODUCTS_FOR_BOX;
    }
    if (completedSteps[0] === selectedBackButtonOnStep) {
      return STEPS.SELECT_PRODUCTS_FOR_BOX;
    }
  }
  if (editingBoxTypeBoxId) {
    if (!selectedForwardButtonOnStep && !selectedBackButtonOnStep) {
      return STEPS.BOX_TYPE_SELECTION;
    }
    if (completedSteps[0] === selectedBackButtonOnStep) {
      return STEPS.BOX_TYPE_SELECTION;
    }
    if (completedSteps[1] === selectedForwardButtonOnStep) {
      return completedSteps[1];
    }
  }
  if (selectedBackButtonOnStep) {
    const index = completedSteps.findIndex(step => step === selectedBackButtonOnStep);
    return index === -1 ? completedSteps[completedSteps.length - 1] : completedSteps[index - 1];
  }
  if (selectedForwardButtonOnStep) {
    const index = completedSteps.findIndex(step => step === selectedForwardButtonOnStep);
    if (index !== -1 && completedSteps[index + 1]) {
      return completedSteps[index + 1];
    }
  }
  if (!_isEmpty(productCardSelections) && isBoxTypeSpecificDataCompleted && !isAddonStepRestricted) {
    return STEPS.SELECT_ADDONS_FOR_BOX;
  }
  if (isBoxTypeSpecificDataCompleted) {
    return STEPS.SELECT_PRODUCTS_FOR_BOX;
  }
  if (selectedForwardButtonOnStep === STEPS.BOX_TYPE_SELECTION && selectedBoxType) {
    return BOX_TYPE_TO_STEP[selectedBoxType];
  }
  if (selectedForwardButtonOnStep === STEPS.BOX_NAMING) {
    return STEPS.BOX_TYPE_SELECTION;
  }
  if (boxInfoStepCompleted || alreadyHaveBoxInCartOrSaveForLater) {
    return STEPS.BOX_NAMING;
  }
  return STEPS.BOX_INTRODUCTION_INFO;
});
export const canGoBackwardsSelector = createSelector(completedStepsSelector, currentStepSelector, (completedSteps, currentStep) => {
  const currentStepIndex = completedSteps.findIndex(step => step === currentStep);
  return currentStepIndex === -1 ? completedSteps.length > 0 : Boolean(completedSteps[currentStepIndex - 1]);
});
export const isOpenEditQuantityInCartPopup = createSelector(editQuantityInCartItemDataSelector, editQuantityInCartItemData => {
  return Boolean(editQuantityInCartItemData);
});
export const isDisableBoxBuilderNextButtonSelector = createSelector(selectedProductsAmountSelector, allValidationsSelector, (selectedProductsAmount, allValidations) => selectedProductsAmount < 1 || Object.values(allValidations).some(errorObject => !errorObject.isSuccessed));
function getModifiedItem(item, cartItems, editBoxBuilderItemsId, boxItemsId) {
  if (!editBoxBuilderItemsId) {
    return item;
  }
  const defineFunction = defineAsSplittedItem(item, boxItemsId);
  const splittedItem = cartItems.find(defineFunction);
  if (!item.boxItemsId && splittedItem) {
    //is item reminder and exist box shared part
    const boxEntriesItems = cartItems.filter(defineFunction);
    const arrOfSelectedVariantIndexes = boxEntriesItems.map(({
      variants
    }) => variants[0].variantIndex);
    const variants = item.variants.reduce((acc, v) => {
      if (!arrOfSelectedVariantIndexes.includes(v.variantIndex)) {
        acc.push(v);
      }
      return acc;
    }, []);
    return _objectSpread({}, item, {
      variants
    });
  }
  return item;
}
function getCurrentCartQuantitiesAndQuantitiesInThisBox({
  currentItemCartQuantities,
  editedBoxItemsId,
  currentItem,
  variantIndex,
  cartItems,
  editedQuantitiesInCart
}) {
  const isCurrentVariantOfItemIsSharedInAnotherBox = cartItems.some(cartItem => {
    if (!cartItem.boxItemsId) {
      return false;
    }
    if (cartItem.asRelatedItemId !== currentItem.asRelatedItemId) {
      return false;
    }
    if (cartItem.prodId !== currentItem.prodId) {
      return false;
    }
    if (editedBoxItemsId && cartItem.boxItemsId === editedBoxItemsId) {
      return false;
    }
    return cartItem.variants.some(variant => variant.variantIndex === variantIndex);
  });
  if (!editedBoxItemsId || !currentItem.boxItemsId) {
    return {
      quantitiesInCart: editedQuantitiesInCart || currentItemCartQuantities,
      quantitiesInThisBox: null,
      isCurrentVariantOfItemIsSharedInAnotherBox
    };
  }
  const standaloneSharedItem = cartItems.find(cartItem => {
    const isShared = cartItem.asRelatedItemId === currentItem.asRelatedItemId && cartItem.prodId === currentItem.prodId && cartItem.variants.some(variant => variant.variantIndex === variantIndex);
    return isShared && !isInBox(cartItem);
  });
  const currentSharedItemVariant = standaloneSharedItem === null || standaloneSharedItem === void 0 ? void 0 : standaloneSharedItem.variants.find(variant => variant.variantIndex === variantIndex);
  const currentSharedItemVariantQuantities = currentSharedItemVariant === null || currentSharedItemVariant === void 0 ? void 0 : currentSharedItemVariant.quantities;
  const standaloneQuantities = currentSharedItemVariantQuantities || new Array(currentItemCartQuantities.length).fill(0);
  const quantitiesInCart = editedQuantitiesInCart || standaloneQuantities;
  return {
    quantitiesInThisBox: currentItemCartQuantities,
    quantitiesInCart,
    isCurrentVariantOfItemIsSharedInAnotherBox
  };
}
function getItemQuantityPerBox({
  isEditMode,
  cartItem,
  reduxValue
}) {
  var _cartItem$quantityPer;
  const valueFromCart = (_cartItem$quantityPer = cartItem.quantityPerBox) !== null && _cartItem$quantityPer !== void 0 ? _cartItem$quantityPer : 0;
  if (isFinite(reduxValue)) {
    return reduxValue !== null && reduxValue !== void 0 ? reduxValue : 0;
  }
  return isEditMode ? valueFromCart : 0;
}
function getIsSelectedCardValue({
  editBoxBuilderItemsId,
  cartItems,
  cartItem,
  reduxValue
}) {
  if (_isBoolean(reduxValue)) {
    return reduxValue;
  }
  const box = cartItems.find(i => i._id === editBoxBuilderItemsId);
  const valueFromCart = Boolean(cartItem && editBoxBuilderItemsId && (box === null || box === void 0 ? void 0 : box.boxItemsId) === (cartItem === null || cartItem === void 0 ? void 0 : cartItem.boxItemsId));
  return editBoxBuilderItemsId ? valueFromCart : false;
}
function getQuantitiesOfCardItem({
  isEditMode,
  cartItem,
  variantIndex,
  isAddonFlow,
  itemVariantSelections,
  variantQuantities
}) {
  var _itemVariantSelection, _cartItem$variants, _cartItem$variants$fi;
  const selectedQuantity = isAddonFlow ? variantQuantities || [0] : (_itemVariantSelection = itemVariantSelections === null || itemVariantSelections === void 0 ? void 0 : itemVariantSelections.quantities) !== null && _itemVariantSelection !== void 0 ? _itemVariantSelection : variantQuantities;
  if (selectedQuantity) {
    return selectedQuantity;
  }
  const valueFromCart = (_cartItem$variants = cartItem.variants) === null || _cartItem$variants === void 0 ? void 0 : (_cartItem$variants$fi = _cartItem$variants.find(v => v.variantIndex === variantIndex)) === null || _cartItem$variants$fi === void 0 ? void 0 : _cartItem$variants$fi.quantities;
  const quantities = isEditMode ? valueFromCart : selectedQuantity;
  return quantities || [];
}
export const otherSpecialBoxProductsSelector = createSelector(editingBoxSelector, cartItemsSelector, (editingBox, cartItems) => {
  if (!editingBox) {
    return [];
  }
  return cartItems.filter(cartItem => {
    if (cartItem.boxItemsId !== editingBox.boxItemsId) {
      return false;
    }
    return isItemBoxSpecialNotecard(cartItem) || isItemBoxSpecialCrinklePaper(cartItem);
  });
});
function getInitialEditingBoxBuilderState(state) {
  return _objectSpread({}, state, {
    boxBuilder: _objectSpread({}, initialState, {
      currentBoxesQuantity: null,
      editingBoxTypeBoxId: state.boxBuilder.editingBoxTypeBoxId,
      editingBoxNameBoxId: state.boxBuilder.editingBoxNameBoxId,
      editBoxBuilderItemsId: state.boxBuilder.editBoxBuilderItemsId
    })
  });
}
export const isBoxActuallyEditedSelector = createSelector(state => state, getInitialEditingBoxBuilderState, (currentState, stateWithInitialEditingBoxBuilderState) => {
  const editingBoxNameBoxId = editingBoxNameBoxIdSelector(stateWithInitialEditingBoxBuilderState);
  const editingBoxTypeBoxId = editingBoxTypeBoxIdSelector(stateWithInitialEditingBoxBuilderState);
  const editBoxBuilderItemsId = editBoxBuilderItemsIdSelector(stateWithInitialEditingBoxBuilderState);
  if (![editingBoxNameBoxId, editingBoxTypeBoxId, editBoxBuilderItemsId].some(id => Boolean(id))) {
    return false;
  }
  const currentName = selectedOrExistedBoxNameSelector(currentState);
  const initialName = selectedOrExistedBoxNameSelector(stateWithInitialEditingBoxBuilderState);
  if (currentName !== initialName) {
    return true;
  }
  const currentBoxType = selectedOrExistedBoxTypeSelector(currentState);
  const initialBoxType = selectedOrExistedBoxTypeSelector(stateWithInitialEditingBoxBuilderState);
  if (currentBoxType !== initialBoxType) {
    return true;
  }
  if (currentBoxType === SPECIAL_PRODUCT_TYPES.COLORED_BOX) {
    const currentData = selectedOrExistedColoredBoxInfoSelector(currentState);
    const initialData = selectedOrExistedColoredBoxInfoSelector(stateWithInitialEditingBoxBuilderState);
    if ((initialData === null || initialData === void 0 ? void 0 : initialData.color) !== (currentData === null || currentData === void 0 ? void 0 : currentData.color)) {
      return true;
    }
  }
  if (currentBoxType === SPECIAL_PRODUCT_TYPES.FULLY_CUSTOM_BOX) {
    const currentData = selectedOrExistedFullyCustomBoxInfoSelector(currentState);
    const initialData = selectedOrExistedFullyCustomBoxInfoSelector(stateWithInitialEditingBoxBuilderState);
    if ((currentData === null || currentData === void 0 ? void 0 : currentData.note) !== (initialData === null || initialData === void 0 ? void 0 : initialData.note)) {
      return true;
    }
  }
  if (currentBoxType === SPECIAL_PRODUCT_TYPES.CUSTOM_BOX) {
    const currentData = selectedOrExistedCustomBoxInfoSelector(currentState);
    const initialData = selectedOrExistedCustomBoxInfoSelector(stateWithInitialEditingBoxBuilderState);
    if (!_isEqual(currentData, initialData)) {
      return true;
    }
  }
  const editingBox = editingBoxSelector(currentState);
  if (!editingBox) {
    return false;
  }
  const addonList = addOnProductsForBoxSelector(currentState);
  const cartItems = cartItemsSelector(currentState);
  const {
    boxItemsId
  } = cartItems.find(i => i._id === editingBox._id);
  const productCardItemsInitialState = productCardItemsSelector(stateWithInitialEditingBoxBuilderState);
  const addonCardItemsInitialState = addonCardItemsSelector(stateWithInitialEditingBoxBuilderState);
  const currentBoxesQuantityInitialState = currentBoxesQuantitySelector(stateWithInitialEditingBoxBuilderState);
  const editedQuantityInCartInitialState = editedQuantityInCartDataSelector(stateWithInitialEditingBoxBuilderState);
  const nextCartItemsForInitialState = editBoxItems({
    cartItems,
    boxItemsId,
    addonList,
    productCardItems: productCardItemsInitialState,
    addonCardItems: addonCardItemsInitialState,
    currentBoxesQuantity: currentBoxesQuantityInitialState || 0,
    editedQuantityInCart: editedQuantityInCartInitialState
  });
  const productCardItemsCurrentState = productCardItemsSelector(currentState);
  const addonCardItemsCurrentState = addonCardItemsSelector(currentState);
  const currentBoxesQuantityCurrentState = currentBoxesQuantitySelector(currentState);
  const editedQuantityInCartCurrentState = editedQuantityInCartDataSelector(currentState);
  const nextCartItemsForCurrentState = editBoxItems({
    cartItems,
    boxItemsId,
    addonList,
    productCardItems: productCardItemsCurrentState,
    addonCardItems: addonCardItemsCurrentState,
    currentBoxesQuantity: currentBoxesQuantityCurrentState || 0,
    editedQuantityInCart: editedQuantityInCartCurrentState
  });
  return !_isEqual(nextCartItemsForInitialState, nextCartItemsForCurrentState);
});
export const hasUnsavedChangesSelector = createSelector(boxNameSelector, isEditingBoxTypeBoxIdSelector, isEditingBoxNameBoxIdSelector, editBoxBuilderItemsIdSelector, isBoxActuallyEditedSelector, (boxName, isEditingBoxTypeBoxId, isEditingBoxNameBoxId, editBoxBuilderItemsId, isBoxActuallyEdited) => {
  if (isEditingBoxTypeBoxId || isEditingBoxNameBoxId || editBoxBuilderItemsId) {
    return isBoxActuallyEdited;
  }
  return Boolean(boxName);
});
export const uploadedCustomBoxLogoSelector = createSelector(customBoxInfoValuesSelector, customBoxInfo => {
  var _customBoxInfo$logo;
  return customBoxInfo === null || customBoxInfo === void 0 ? void 0 : (_customBoxInfo$logo = customBoxInfo.logo) === null || _customBoxInfo$logo === void 0 ? void 0 : _customBoxInfo$logo.originalPreview;
});
export const uploadedBrownBoxLogoSelector = createSelector(brownBoxInfoValuesSelector, brownBoxInfo => {
  var _brownBoxInfo$tapeLog;
  return brownBoxInfo === null || brownBoxInfo === void 0 ? void 0 : (_brownBoxInfo$tapeLog = brownBoxInfo.tapeLogo) === null || _brownBoxInfo$tapeLog === void 0 ? void 0 : _brownBoxInfo$tapeLog.originalPreview;
});
export const selectedBrownBoxDataSelector = createSelector(specialBoxProductsSelector, specialProducts => {
  const product = specialProducts.find(product => product.specialProductType === SPECIAL_PRODUCT_TYPES.BROWN_BOX);
  return getBoxProductDataForBoxBuilderPopup(product);
});
export const tapeDataSelector = createSelector(specialBoxProductsSelector, specialProducts => {
  const product = specialProducts.find(product => product.specialProductType === SPECIAL_PRODUCT_TYPES.BOX_TAPE);
  return getBoxProductDataForBoxBuilderPopup(product);
});